import { useAuth0 } from '@auth0/auth0-react';
import { Button } from './Button';
import { Config } from '../App';

export const HeaderBar = () => {
    const { logout } = useAuth0();
    return (
        <div className="flex h-12 bg-white justify-between align-middle items-center px-5 shadow-md fixed inset-0 overflow-x-scroll">
            <div className="flex gap-8">
                <a href='/' className="font-medium hover:cursor-pointer">Home</a>
                <a href='/clients' className="font-medium hover:cursor-pointer">Clients</a>
                <div className="font-medium hover:cursor-pointer">Schedule</div>
                <div className="font-medium hover:cursor-pointer">Chat</div>
            </div>
            <div className="flex pl-8">
                <Button onClick={() => logout({ logoutParams: { returnTo: `${Config.URL}/login` } })} className="flex bg-blue text-white h-6 text-base items-center">Log Out</Button>
            </div>
        </div>
    )
}